import React from "react"
import { Badge, Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import Pagination from "../BlogContent/Pagination"

const DeepDiveCardView = ({ items, numPages, currentPage, cardGrid = "col-lg-4 col-md-6" }) => {
  return (
    <div className="projects-area pb-70" style={{ marginTop: "20px" }}>
      <Container>
        <Row>
          {items.map(({ node: deepdive }) => (
            <Col lg={4} md={6} style={{ display: "flex" }}>
              <div className="single-projects-box">
                <Link to={deepdive.fields.slug} className="link-btn">
                  <div className="image">
                    <GatsbyImage alt={"Deep Dive zum Thema " + deepdive.frontmatter.title}
                                 image={getImage(deepdive.frontmatter.image)} />
                    <Link to={deepdive.fields.slug} className="link-btn">
                      <FontAwesomeIcon style={{ verticalAlign: 0 }} icon={faPlus} />
                    </Link>
                  </div>
                </Link>

                <div className="content">
                  <div>
                    <h2>
                      <Link to={deepdive.fields.slug} style={{ textDecoration: "none" }}>
                        {deepdive.frontmatter.title}
                      </Link>
                    </h2>

                    <Badge variant={"primary"} pill={true}>
                      {deepdive.frontmatter.keyword}</Badge>
                  </div>
                </div>
              </div>
            </Col>
          ))
          }
        </Row>
      </Container>
      <Pagination link={"deep-dive"} currentPage={currentPage} numPages={numPages} />
    </div>
  )
}

export default DeepDiveCardView
