import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import React from "react"
import { graphql } from "gatsby"
import PageBanner from "../components/Common/PageBanner"
import Seo from "../components/App/Seo"
import DeepDiveCardView from "../components/deepdive/DeepDiveCardView"

const DeepdiveOverview = ({ data, pageContext }) => {
  return (
    <Layout>
      <Seo title={"Übersicht über das LOGENTIS Deep Dive"}
           description={"Verschiedene Konzepte unserer verwendeten Technologien werden hier im Detail erklärt."}
      />
      <Navbar />
      <PageBanner
        pageTitle="LOGENTIS Deep Dive"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Deep Dive"
      />
      <DeepDiveCardView items={data.allMarkdownRemark.edges} numPages={pageContext.numPages}
                        currentPage={pageContext.currentPage} />
      <Footer />
    </Layout>
  )
}

export default DeepdiveOverview

export const pageQuery = graphql`
  query DeepDiveOverview($skip: Int!, $limit: Int!) {
     site {
        siteMetadata {
            siteUrl
        }
     }
     allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      filter: {fields: {collection: {eq: "deepdive"}}}
      skip: $skip) 
      {
        edges {
            node {
              id
              html
              excerpt(pruneLength: 250)
              fields {
                slug
              }
              frontmatter {
                title
                keyword
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
        }
     }
  }
`